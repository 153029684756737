<template>
  <div class="flex justify-between px-6 py-3 border-b border-custom-gray-9 sticky custom-top z-10">
    <inline-button-input
      v-model="commentsKeyword"
      container-class="max-w-full w-80"
      placeholder="Enter Keywords..."
      :btn-props="{
        text: 'Search',
        disabled: commentsKeyword.length === 0,
        variant: 'btn-primary'
      }"
      :input-props="{
        containerClass: 'flex items-center w-full'
      }"
      @click="handleSearch()"
    />
    <icon-button
      title="Refresh"
      class="w-7.5 h-7.5"
      icon="icons/arrowsClockwise.svg"
      active
      active-btn-class="bg-custom-green-1 bg-opacity-20"
      active-icon-class="text-custom-green-1"
      @click="handleRefresh()"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue';
import useComments from '@/components/brand-assurance-comments/comments.js';

export default {
    name: 'CommentsHeader',

    components: {
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        InlineButtonInput: defineAsyncComponent(() => import('@/components/InlineButtonInput.vue'))
    },

    setup () {
        const commentsKeyword = ref('');
        const { filterCommentsList, commentsList, completeCommentList } = useComments();
        const handleSearch = () => {
            filterCommentsList(commentsKeyword.value);
        };
        const handleRefresh = () => {
            commentsList.value = completeCommentList.value;
        };

        return {
            commentsKeyword,
            handleSearch,
            handleRefresh
        };
    }
};
</script>
<style scoped>
  .custom-top {
      top: 2.2rem;
      background: white;
  }
</style>
